import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "*",
    redirect: "/home",
  },
  {
    name: "home",
    component: () => import("./view/home"),
    meta: {
      title: "首页",
    },
  },
  /**
   * 浩世界相关路由
   */
  {
    name: "about",
    component: () => import("./view/h-word/about.vue"),
    meta: {
      title: "关于浩亭",
    },
  },
  {
    name: "news",
    component: () => import("./view/h-word/news.vue"),
    meta: {
      title: "新闻中心",
    },
  },
  {
    name: "newsDetail",
    component: () => import("./view/h-word/newsDetail.vue"),
    meta: {
      title: "新闻中心",
    },
  },
  {
    name: "tecnews",
    component: () => import("./view/h-word/tecnews.vue"),
    meta: {
      title: "杂志-TecNews",
    },
  },
  /**
   * 浩服务相关路由
   */
  {
    name: "tool",
    component: () => import("./view/h-serve/tool.vue"),
    meta: {
      title: "数字工具",
    },
  },
  {
    name: "hanconfig",
    component: () => import("./view/h-serve/hanconfig.vue"),
    meta: {
      title: "Han Confgurator",
    },
  },
  {
    name: "eshop",
    component: () => import("./view/h-serve/eshop.vue"),
    meta: {
      title: "eShop",
    },
  },
  {
    name: "sample",
    component: () => import("./view/h-serve/sample.vue"),
    meta: {
      title: "样品申请",
    },
  },
  {
    name: "sampleForm",
    component: () => import("./view/h-serve/sampleForm.vue"),
    meta: {
      title: "样品申请",
    },
  },
  {
    name: "knowledge",
    component: () => import("./view/h-serve/knowledge.vue"),
    meta: {
      title: "知识中心",
    },
  },
  {
    name: "knowledgeDetail",
    component: () => import("./view/h-serve/knowledgeDetail.vue"),
    meta: {
      title: "知识中心",
    },
  },
  {
    name: "customer",
    component: () => import("./view/h-serve/customer.vue"),
    meta: {
      title: "在线客服",
    },
  },
  {
    name: "subscribe",
    component: () => import("./view/h-serve/subscribe.vue"),
    meta: {
      title: "订阅电子版",
    },
  },
  {
    name: "myharting",
    component: () => import("./view/h-serve/myharting.vue"),
    meta: {
      title: "myharting",
    },
  },
  /**
   * 浩方案相关路由
   */
  {
    name: "products",
    component: () => import("./view/h-scheme/products.vue"),
    meta: {
      title: "标准产品",
    },
  },
  {
    name: "productDetail",
    component: () => import("./view/h-scheme/productsDetail01.vue"),
    meta: {
      title: "产品详情",
    },
  },
  {
    name: "productTypeDetail",
    path: "productTypeDetail",
    component: () => import("./view/h-scheme/productTypeDetail.vue"),
    meta: {
      title: "产品类型",
    },
  },
  {
    name: "productsDetailHar",
    component: () => import("./view/h-scheme/productsDetailHar.vue"),
    meta: {
      title: "浩亭 har-modular®",
    },
  },
  {
    name: "custom",
    component: () => import("./view/h-scheme/custom.vue"),
    meta: {
      title: "定制化服务",
    },
  },
  {
    name: "customDetail",
    component: () => import("./view/h-scheme/customDetail.vue"),
    meta: {
      title: "定制化服务",
    },
  },
  {
    name: "markets",
    component: () => import("./view/h-scheme/markets.vue"),
    meta: {
      title: "行业和市场",
    },
  },
  {
    name: "marketsDetail",
    component: () => import("./view/h-scheme/marketsDetail.vue"),
    meta: {
      title: "行业和市场",
    },
  },
  {
    name: "videos",
    component: () => import("./view/h-scheme/videos.vue"),
    meta: {
      title: "视频中心",
    },
  },
  {
    name: "videosDetail",
    component: () => import("./view/h-scheme/videosDetail.vue"),
    meta: {
      title: "视频中心",
    },
  },
  {
    name: "story",
    component: () => import("./view/h-scheme/story.vue"),
    meta: {
      title: "客户故事",
    },
  },
  {
    name: "meeting",
    component: () => import("./view/h-scheme/meeting.vue"),
    meta: {
      title: "在线会议",
    },
  },
  {
    name: "meetingForm",
    component: () => import("./view/h-scheme/meetingForm.vue"),
    meta: {
      title: "在线会议",
    },
  },
  {
    name: "meetingDetail",
    component: () => import("./view/h-scheme/meetingDetail.vue"),
    meta: {
      title: "在线会议",
    },
  },
  // {
  //   name: "user",
  //   component: () => import("./view/user"),
  //   meta: {
  //     title: "会员中心",
  //   },
  // },
  // {
  //   name: "cart",
  //   component: () => import("./view/cart"),
  //   meta: {
  //     title: "购物车",
  //   },
  // },
  // {
  //   name: "goods",
  //   component: () => import("./view/goods"),
  //   meta: {
  //     title: "商品详情",
  //   },
  // },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const router = new Router({routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
