import Vue from 'vue';
import App from './App';
import { router } from './router';
import 'amfe-flexible';
import '@/styles/index.less'
import { Notify } from 'vant';
Vue.use(Notify);
new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
